<template>
  <v-container fluid>
    <modal-eliminar :objModalEliminar="objModalEliminar"></modal-eliminar>
    <modal-habilitar :objModalHabilitar="objModalHabilitar"></modal-habilitar>
    <modal-inhabilitar :objModalInhabilitar="objModalInhabilitar"></modal-inhabilitar>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> GESTIONAR CUPONES </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <div class="col-12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'cuponesPanel' }"
                    >Cupones</router-link
                  >
                </p>
              </div>
              <v-col cols="12" class="text-right">
                <v-btn
                  v-show="validarPermiso('cupones.crear')"
                  :to="{ name: 'cuponesCrearPanel' }"
                  color="primary"
                  dark
                  large=""
                >
                  <v-icon left="" large="">mdi-plus-circle</v-icon> NUEVO CUPON
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  small
                  title="Limpiar filtros"
                  fab
                  dark
                  color="blue-grey"
                  depressed
                  @click="limpiarFiltros()"
                >
                  <v-icon dark>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                <v-select
                  v-model="cmbCantidadRegistros"
                  item-text="texto"
                  item-value="valor"
                  :items="arrCantidadRegistros"
                  label="Cantidad de registros"
                  @change="
                    paginaActual = 1;
                    listarRegistros();
                  "
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-select
                  clearable
                  v-model="filtroDescuento"
                  item-text="nombre"
                  item-value="idtipo_descuento"
                  :items="cmbTipoDescuento"
                  label="Tipo de Descuento"
                  @change="
                    paginaActual = 1;
                    listarRegistros();
                  "
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-select
                  clearable
                  v-model="filtroUso"
                  item-text="nombre"
                  item-value="idtipo_uso"
                  :items="cmbTipoUso"
                  label="Tipo de Uso"
                  @change="
                    paginaActual = 1;
                    listarRegistros();
                  "
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                <v-text-field
                  @keyup.prevent.enter="
                    paginaActual = 1;
                    listarRegistros();
                  "
                  @click:clear="
                    paginaActual = 1;
                    filtroNombre = '';
                    listarRegistros();
                  "
                  @keyup.prevent.delete="
                    paginaActual = 1;
                    listarRegistros();
                  "
                  v-model="filtroNombre"
                  clearable
                  autocomplete="off"
                  outlined
                  dense
                  label="Nombre..."
                ></v-text-field>
              </v-col>

              <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                <v-menu :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :value="formatFiltroFechaDesde"
                      dense
                      clearable
                      label="Desde"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="
                        paginaActual = 1;
                        filtroFechaDesde = '';
                        listarRegistros();
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filtroFechaDesde"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                <v-menu :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :value="formatFiltroFechaHasta"
                      dense
                      clearable
                      label="Hasta"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="
                        paginaActual = 1;
                        filtroFechaHasta = '';
                        listarRegistros();
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filtroFechaHasta"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="12" cols="12" class="text-center">
                <v-btn class="my-0" color="info" elevation="" @click="filtrar()">
                  <v-icon>mdi-filter</v-icon> Filtrar
                </v-btn>
              </v-col>

              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-simple-table dense="" fixed-header="" ref="tblListado">
                  <template v-slot:default>
                    <thead class="text-no-wrap">
                      <tr>
                        <th class="text-center"> <p>ACCIONES</p> </th>
                        <!-- <th class="text-center"> <p>N° </p> </th>
                        <th class="text-center"> <p>Fecha </p> </th>
                        <th class="text-center"> <p>Correo </p> </th>
                        <th class="text-center"> <p>Link a la imagen </p> </th>
                        <th class="text-center"> <p>Cupón creado</p> </th> -->
                        
                        <th class="text-center"> <p>NOMBRE</p> </th>
                        <th class="text-center"> <p>TIPO DESCUENTO</p> </th>
                        <th class="text-center"> <p>MONTO S/.</p> </th>
                        <th class="text-center"> <p>DESDE</p> </th>
                        <th class="text-center"> <p>HASTA</p> </th>
                        <th class="text-center"> <p>USO</p> </th>
                        <th class="text-center"> <p>ESTADO</p> </th>
                      </tr>
                    </thead>
                    <tbody class="text-no-wrap">
                      <tr v-for="item in arrRegistrosTabla" :key="item.idcupon">
                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                dark=""
                                depressed=""
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left="">mdi-cursor-default-click</v-icon>
                                Acciones
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-show="validarPermiso('cupones.editar')"
                                :to="{
                                  name: 'cuponesEditarPanel',
                                  params: { idcupon: item.idcupon },
                                }"
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-pencil</v-icon> Editar
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="item.estado"
                                v-show="validarPermiso('cupones.inhabilitar')"
                                @click="
                                  objModalInhabilitar = {
                                    idcupon: item.idcupon,
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-close</v-icon> Inhabilitar
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-else
                                v-show="validarPermiso('cupones.habilitar')"
                                @click="
                                  objModalHabilitar = {
                                    idcupon: item.idcupon,
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-check</v-icon> Habilitar
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-show="validarPermiso('cupones.eliminar')"
                                @click="
                                  objModalEliminar = {
                                    idcupon: item.idcupon,
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-trash-can</v-icon> Eliminar
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                        <!-- <td v-text="item.idcupon.toString().padStart(7,0)" ></td>
                        <td v-text="item.fecha_desde+' - '+item.fecha_hasta" ></td>
                        <td v-text="item.registro_pedido_descuento.correo" ></td>
                        <td >
                            <a :href="assetAWS('cupon/boleta/'+item.registro_pedido_descuento.boleta)" target="_blank" v-text="item.registro_pedido_descuento.boleta"></a>
                        </td>
                        <td v-text="item.nombre" ></td> -->
                        <td v-text="item.nombre"></td>
                        <td v-if="item.tipo_descuento != null" v-text="item.tipo_descuento.nombre" ></td>
                        <td v-text="item.monto"></td>
                        <td v-text="$moment(item.fecha_desde).format('DD/MM/YYYY')"></td>
                        <td v-text="$moment(item.fecha_hasta).format('DD/MM/YYYY')"></td>
                        <td v-if="item.tipo_uso != null" v-text="item.tipo_uso.nombre" ></td>
                        <td>
                          <v-chip small class="ma-2" color="green" text-color="white" v-if="item.estado" > Habilitado </v-chip>
                          <v-chip small class="ma-2" color="red" text-color="white" v-else > Inhabilitado </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p>
                  Mostrando del registro <span v-text="primerRegistro"></span> al
                  <span v-text="ultimoRegistro"></span> de un total de
                  <span v-text="totalRegistro"></span> filas
                </p>
              </v-col>

              <v-col cols="12 text-center" v-show="loaderListado">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>

              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-pagination
                  circle
                  v-model="paginaActual"
                  :length="cantidadCupones"
                  :total-visible="7"
                  @input="listarRegistros()"
                  :disabled="loaderListado"
                ></v-pagination>
              </v-col>

              <v-col cols="12" v-show="alertRegistroNoEcontrado">
                <v-alert type="error" dense outlined class="text-center">
                  NO HAY REGISTROS DISPONIBLES PARA MOSTRAR
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import modalEliminar from "./Eliminar";
import modalHabilitar from "./Habilitar";
import modalInhabilitar from "./Inhabilitar";

export default {
  components: {
    modalEliminar,
    modalHabilitar,
    modalInhabilitar,
  },
  data() {
    return {
      arrCantidadRegistros: [
        {
          texto: 10,
          valor: 10,
        },
        {
          texto: 30,
          valor: 30,
        },
        {
          texto: 50,
          valor: 50,
        },
        {
          texto: 100,
          valor: 100,
        },
        {
          texto: 500,
          valor: 500,
        },
      ],
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      cmbTipoDescuento: [],
      cmbTipoUso: [],
      loaderListado: false,
      alertRegistroNoEcontrado: false,
      cmbCantidadRegistros: 10,
      arrRegistrosTabla: [],
      paginaActual: 1,
      cantidadCupones: 1,
      primerRegistro: 0,
      ultimoRegistro: 0,
      totalRegistro: 0,
      filtroClase: "",
      filtroDescuento: "",
      filtroUso: "",
      filtroFechaDesde: "",
      filtroFechaHasta: "",
      filtroNombre: "",
      objModalEliminar: null,
      objModalHabilitar: null,
      objModalInhabilitar: null,
    };
  },

  computed: {
    formatFiltroFechaDesde() {
      return this.filtroFechaDesde
        ? this.$moment(this.filtroFechaDesde).format("DD/MM/YYYY")
        : "";
    },
    formatFiltroFechaHasta() {
      return this.filtroFechaHasta
        ? this.$moment(this.filtroFechaHasta).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    listarRegistros() {
      this.arrRegistrosTabla = [];
      this.loaderListado = true;
      this.alertRegistroNoEcontrado = false;
      this.axios({
        method: "GET",
        url: "api/panel/cupones",
        params: {
          cantidadRegistros: this.cmbCantidadRegistros,
          paginaActual: this.paginaActual,
          filtroClase: this.filtroClase,
          filtroDescuento: this.filtroDescuento,
          filtroUso: this.filtroUso,
          filtroFechaDesde: this.filtroFechaDesde,
          filtroFechaHasta: this.filtroFechaHasta,
          filtroNombre: this.filtroNombre,
        },
      })
        .then((response) => {
          let listado = response.data.data;

          this.arrRegistrosTabla = listado.data;
          this.paginaActual = listado.current_page;
          this.cantidadCupones = listado.last_page;
          this.primerRegistro = listado.from;
          this.ultimoRegistro = listado.to;
          this.totalRegistro = listado.total;

          if (this.arrRegistrosTabla.length === 0) {
            this.alertRegistroNoEcontrado = true;
          } else {
            this.alertRegistroNoEcontrado = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .then(() => {
          this.loaderListado = false;
          this.$vuetify.goTo(this.$refs.tblListado);
        });
    },

    getFiltros() {
      this.axios({
        method: "POST",
        url: "api/panel/cupones/filtros",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.cmbTipoUso = response.data.data.tipoUso;
            this.cmbTipoDescuento = response.data.data.tipoDescuento;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    filtrar() {
      this.paginaActual = 1;
      this.listarRegistros();
    },

    limpiarFiltros() {
      this.cmbCantidadRegistros = 10;
      this.paginaActual = 1;
      this.filtroClase = "";
      this.filtroDescuento = "";
      this.filtroUso = "";
      this.filtroFechaDesde = "";
      this.filtroFechaHasta = "";
      this.filtroNombre = "";
      this.listarRegistros();
    },
  },

  created() {
    if (!this.validarPermiso("cupones.listado")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    this.listarRegistros();
    this.getFiltros();
  },
};
</script>
